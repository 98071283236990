var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-row',[_c('a-col',{attrs:{"span":"20"}},[_c('a-form-model',{attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"档案编号"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"档案名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"关键词"},model:{value:(_vm.form.keyWord),callback:function ($$v) {_vm.$set(_vm.form, "keyWord", $$v)},expression:"form.keyWord"}})],1),_c('a-form-model-item',[_c('a-cascader',{staticStyle:{"width":"240px"},attrs:{"placeholder":"档案类型","options":_vm.typeList,"fieldNames":{
                label: 'name',
                value: 'value',
                children: 'children',
              }},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":"4"}},[_c('div',{staticClass:"right"},[_c('a-space',[(_vm.$getPermission(_vm.$route.path + '/transfer'))?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/transfer')}}},[_vm._v("移交档案申请")]):_vm._e(),(_vm.$getPermission(_vm.$route.path + '/lend'))?_c('a-button',{staticStyle:{"background":"#ff8c18","border":"1px solid #ff8c18"},attrs:{"type":"primary"},on:{"click":_vm.lend}},[_c('span',[_vm._v("出借申请")]),(_vm.selectedList.length > 0)?_c('span',[_vm._v("("+_vm._s(_vm.selectedList.length)+")")]):_vm._e()]):_vm._e()],1)],1)])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"档案名称"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goDetail(text)}}},[(text.level === 'secret')?_c('a-tag',{attrs:{"color":"gold"}},[_vm._v("秘密")]):_vm._e(),(text.level === 'confidential')?_c('a-tag',{attrs:{"color":"orange"}},[_vm._v("机密")]):_vm._e(),(text.level === 'topsecret')?_c('a-tag',{attrs:{"color":"red"}},[_vm._v("绝密")]):_vm._e(),_c('strong',[_vm._v(" "+_vm._s(text.name)+" ")])],1)]}}])}),_c('a-table-column',{attrs:{"title":"档案编号","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"档案类型"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(" "+_vm._s(_vm.getTypeText(text.type))+" ")]}}])}),_c('a-table-column',{attrs:{"title":"档案状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"arcStatus","dictValue":text.status,"iconType":"text"}})]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":"#","disabled":text.status !== 'inlib'},on:{"click":function($event){$event.preventDefault();return _vm.select(text)}}},[_vm._v(" "+_vm._s(_vm.selectedList.find((item) => item.id === text.id) ? "已选择" : "加入清单")+" ")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }