<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-row>
        <a-col span="20">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.code" placeholder="档案编号" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="档案名称" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.keyWord" placeholder="关键词" />
            </a-form-model-item>
            <a-form-model-item>
              <a-cascader
                style="width: 240px"
                placeholder="档案类型"
                :options="typeList"
                :fieldNames="{
                  label: 'name',
                  value: 'value',
                  children: 'children',
                }"
                v-model="form.type"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col span="4">
          <div class="right">
            <a-space>
              <a-button
                v-if="$getPermission($route.path + '/transfer')"
                type="primary"
                @click="$router.push($route.path + '/transfer')"
                >移交档案申请</a-button
              >
              <a-button
                v-if="$getPermission($route.path + '/lend')"
                type="primary"
                style="background: #ff8c18; border: 1px solid #ff8c18"
                @click="lend"
              >
                <span>出借申请</span>
                <span v-if="selectedList.length > 0"
                  >({{ selectedList.length }})</span
                >
              </a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="档案名称">
          <template slot-scope="text">
            <a href="#" @click.prevent="goDetail(text)">
              <a-tag color="gold" v-if="text.level === 'secret'">秘密</a-tag>
              <a-tag color="orange" v-if="text.level === 'confidential'"
                >机密</a-tag
              >
              <a-tag color="red" v-if="text.level === 'topsecret'">绝密</a-tag>

              <strong>
                {{ text.name }}
              </strong></a
            >
          </template>
        </a-table-column>

        <a-table-column title="档案编号" data-index="code"> </a-table-column>

        <a-table-column title="档案类型">
          <template slot-scope="text">
            {{ getTypeText(text.type) }}
          </template>
        </a-table-column>

        <a-table-column title="档案状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="arcStatus"
              :dictValue="text.status"
              iconType="text"
            />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a
              href="#"
              :disabled="text.status !== 'inlib'"
              @click.prevent="select(text)"
            >
              {{
                selectedList.find((item) => item.id === text.id)
                  ? "已选择"
                  : "加入清单"
              }}
            </a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList } from "@/api/archives";
import archiveType from "@/mixins/archive-type";

export default {
  mixins: [archiveType, watermark],

  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      selectedList: [],
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      const { code, name, type, keyWord } = form;
      let typeValue;
      if (Array.isArray(type)) {
        typeValue = type.join(",");
      }

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        code,
        name,
        type: typeValue,
        keyWord,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },

    goDetail(item) {
      window.localStorage.setItem("archive-detail", JSON.stringify(item));
      this.$router.push(this.$route.path + "/detail");
    },

    select(item) {
      if (this.selectedList.find((element) => element.id === item.id)) {
        console.log("has selected");
        return;
      } else {
        this.selectedList.push(item);
        this.$message.success("已选择" + item.name);
      }
    },
    lend() {
      if (this.selectedList.length === 0) {
        this.$message.error("请选择档案");
        return;
      }
      window.localStorage.setItem(
        "lend-archives",
        JSON.stringify(this.selectedList)
      );
      this.$router.push(this.$route.path + "/lend");
    },
  },
};
</script>